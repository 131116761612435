




































































































import { incidenciaAllDto } from "@/shared/dtos/incidencias/incidenciaAllDto";
import { MessageService } from "@/shared/services/message-service";
import incidenciaModule from "@/store/modules/incidencia-module";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    IncidenciasFormulario: () =>
      import("@/views/proyectos/incidencias/incidenciasFormulario.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    IncidenciasListaMovil: () =>
      import("@/views/proyectos/incidencias/incidenciasListaMovil.vue"),
  },
})
export default class Incidencias_inventario extends Vue {
  public show_dialog_incident: boolean = false;
  public id_inicencia: number = -1;

  created() {
    this.GetIncidencias();
    usuarioModule.getmiusuario();
  }

  public get inidencias() {
    return incidenciaModule.incidencias_proyecto.filter(
      (x) => x.id_inventario === Number.parseInt(this.$route.params.id)
    );
  }
  public get mi_user() {
    return usuarioModule.mi_usuario;
  }

  public GetIncidencias() {
    return incidenciaModule.getincidencias_proyectosActivas();
  }
  editar(obj: incidenciaAllDto) {
    this.id_inicencia = obj.id;
    this.show_dialog_incident = true;
  }

  public close_dialog() {
    this.show_dialog_incident = false;
    this.GetIncidencias();
    this.id_inicencia = -1;
  }

  eliminar(obj: incidenciaAllDto) {
    MessageService.confirm(
      "¿Seguro que desea eliminar la incidencia?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          incidenciaModule.eliminarincidencia(obj).then(() => {
            this.GetIncidencias();
          });
        }
      }
    );
  }
}
